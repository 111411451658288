import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, View, Text, PDFViewer } from "@react-pdf/renderer";

import global from "global";
import transcript from "utils/transcript";
import { pdfStyles } from "pages/scoring";
import { useSelector } from "store/hooks";
import styles from "./result.module.scss";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import request from "request";

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();

  const [shapLoading, setShapLoading] = React.useState(false);

  React.useEffect(() => {
    if (contextApplication.data?.scoring_log === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.content}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
            <Pdf contextApplication={contextApplication} />
          </PDFViewer>
        </div>
        {(contextApplication.data?.created_by.role === "manager" ||
          contextApplication.data?.created_by.role === "admin") && (
          <div className={styles.shap_value}>
            {shapLoading && (
              <div className={styles.loading_shap}>
                <Loading />
              </div>
            )}
            <div className={styles.shap_value_title}>
              Значения Шэпли для принятия решений
            </div>
            {contextApplication.data?.scoring_log && (
              <>
                <iframe
                  title="shap_link"
                  src={contextApplication.data?.scoring_log?.shap_link}
                  style={{ width: "100%" }}
                  onLoad={() => setShapLoading(false)}
                ></iframe>
              </>
            )}
          </div>
        )}
      </div>

      <div className={styles.resultBoxGroup}>
        {contextApplication.data?.scoring_log && <ResultBox />}
      </div>
    </div>
  );
};

export const Pdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  if (!contextApplication.data) return <></>;
  const { email } = contextApplication.data.created_by;
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат скоринга</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.text}>
              <Text>Скоринг проведён экспертом: {email}</Text>
              <Text>
                Дата создания:{" "}
                {new Date(
                  contextApplication.data.scoring_log.created_at
                ).toLocaleString()}
              </Text>
            </View>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {Number(
                      selectedRecommendation?.amount ??
                        contextApplication.data.amount
                    )}{" "}
                    TJS
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Длительность</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {selectedRecommendation?.duration ??
                      contextApplication.data.duration}{" "}
                    мес.
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Процентная ставка</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {(contextApplication.data?.int_rate * 100).toFixed(1)} %
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Пол</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.gender(
                      contextApplication.data.scoring_log.gender
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Возраст</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Семейное положение</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.marital_status(
                      contextApplication.data.scoring_log.marital_status
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Количество исторических кредитов</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.credit_history_count}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Цель кредита</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.sector(
                      contextApplication.data.scoring_log.sector
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Кредит во время экстренного периода</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.is_disaster(
                      contextApplication.data.scoring_log.is_disaster
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Оценка БКИТ</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.bkit_score(
                      contextApplication.data.scoring_log.bkit_score
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Город/Район</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.district(
                      contextApplication.data.scoring_log.district
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Образование</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.education(
                      contextApplication.data.scoring_log.education
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие телефона</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.phone_type(
                      contextApplication.data.scoring_log.phone_type
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие автомобиля</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.has_transport(
                      contextApplication.data.scoring_log.has_transport
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие жилья</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.has_house(
                      contextApplication.data.scoring_log.has_house
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сфера занятости</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.work_place(
                      contextApplication.data.scoring_log.work_place
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Опыт работы в годах</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log?.work_experience}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Ежемесячный доход</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.monthly_income}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Статус деятельности</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.job_status(
                      contextApplication.data.scoring_log.job_status
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Кол-во иждивенцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log?.dependants}</Text>
                </View>
              </View>
              {contextApplication.data.scoring_log?.has_collateral && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Залог</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.has_collateral(
                        contextApplication.data.scoring_log?.has_collateral
                      )}
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data.scoring_log?.co_borrower && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Поручитель/Созаемщик</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.co_borrower(
                        contextApplication.data.scoring_log?.co_borrower
                      )}
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                Результат скоринга:{" "}
                {contextApplication.data?.is_approved ? "Одобрено" : "Отказано"}{" "}
                {selectedRecommendation
                  ? Math.round(selectedRecommendation?.prob)
                  : Number(contextApplication.data?.scoring_log.prediction) *
                    100}
                %
              </Text>
              <Text style={pdfStyles.result}>
                Порог:{" "}
                {(
                  contextApplication.data?.scoring_log?.threshold * 100
                ).toFixed()}
                %
              </Text>
            </View>
          </View>
        </Page>
      )}
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.heading}>Макроданные</Text>
          <Text style={pdfStyles.text}>
            Дата создания:{" "}
            {new Date(
              contextApplication.data?.created_at ?? ""
            ).toLocaleString()}
          </Text>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Инфляция на продовольственные товары</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.food_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Инфляция непотребительских товаров</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.nonfood_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Инфляция услуг</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.services_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Курс рубля</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.rub_exchange}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Курс доллара</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.exrate_usd_avg}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Средний доход населения</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.services_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>ВВП</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.gdp}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Цена на нефть марки Брент</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.brent_oil_price}</Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
